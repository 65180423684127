import * as React from "react"
import {
    Flex,
    Box,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    HStack,
    VStack,
    Button,
    Divider,
    useToast,
    Center,
    Heading,
    Input,
    Textarea,
    InputGroup,
    InputRightElement,
    Stack,
    Checkbox,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Spacer,
    ModalFooter,
    useDisclosure,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Icon,
    FormErrorMessage,
    FormControl, FormLabel,
    AlertDialog, AlertDialogBody, AlertDialogOverlay, AlertDialogFooter, AlertDialogContent, AlertDialogHeader
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
    changeSize,
    initialDescriptionShort,
    isNextPage,
    isPrevPage,
    nextPage,
    prevPage
} from "../../utils/pagination";
import {User} from "../../types/UserTypes";
import {BiFilter} from "react-icons/bi";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CustomMessage} from "../../types/NotificationTypes";
import {sendToAll, sendToGroup} from "../../api/NotificationService";
import {MdFileUpload} from "react-icons/all";
import {CSVReader} from "react-papaparse";
import {ParseResult} from "papaparse";
import CustomTable from "../../components/CustomTable";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import userSearchByPattern from "../../helpers/userSearchHelper";
import { setLoading } from '../../actions/loadingActions';
import { displayErrorMessage } from "../../utils/PopUpMessage";
import { debounce } from 'lodash';
import { PageRequest } from "../../types/ApiTypes";

const schema = yup.object().shape({
    title: yup.string().min(1).required(),
    text: yup.string().max(100).required()
});

export function Notification() {
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenList,
        onOpen: onOpenList,
        onClose: onCloseList
    } = useDisclosure()

    const { register, formState: { errors } } = useForm<CustomMessage>({
        mode: "onBlur",
        resolver: yupResolver(schema),
    });

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [silent, setSilent] = useState(false);
    const [isUserSpecific, setIsUserSpecific] = useState(false);

    let initial : User[] = []
    let initialUsers : User[] = []
    let initialSelectedUsers : User[] = []
    let initialCSVUsers : User[] = []
    const [users, setUsers] = useState(initial);
    const [usersList, setUsersList] = React.useState(initialUsers)
    const [selectedUsersList, setSelectedUsersList] = React.useState(initialSelectedUsers)

    const [pageRequest, setPageRequest] = React.useState(initialDescriptionShort)
    const [totalHits, setTotalHits] = React.useState(0)

    const [csvFile, setCsvFile] = React.useState(initialCSVUsers)
    const [isImportDisabled, setIsImportDisabled] = React.useState(true)

    const [isOpenConfirm, setIsOpenConfirm] = React.useState(false)
    const onCloseConfirm = () => setIsOpenConfirm(false)
    const cancelRef = React.useRef()

    const [sendCountdown, setSendCountdown] = useState(5);
    const [isSendDisabled, setIsSendDisabled] = useState(true);

    useEffect(() => {
        // If countdown reaches 0, enable the button
        if (sendCountdown === 0) {
            setIsSendDisabled(false);
          return;
        } else {
            setIsSendDisabled(true);
        }

        // Set up the interval to decrease the countdown every second
        const intervalId = setInterval(() => {
            setSendCountdown((currentCountdown) => currentCountdown - 1);
        }, 1000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
      }, [sendCountdown]);

    const saveAction = () => {
        let customMessage : CustomMessage = {
            title : title,
            content : content,
            silent: silent,
            users : isUserSpecific ? users.map(user => user.username) : []
        }
        if (!isUserSpecific) {
            sendMessageAll(customMessage)
        } else {
            sendMessageList(customMessage)
        }
        onCloseConfirm();
    };

    const confirmAction = () => {
        if (title == '' || content == '') {
            displayErrorMessage(toast, "The fields Title and Content cannot be empty", "", "bottom");
        } else if ((isUserSpecific) && (users.length == 0)) {
            displayErrorMessage(toast, "Is User Specific", "No Target Users were selected", "bottom");
        } else {
            setSendCountdown(5);
            setIsOpenConfirm(true);
        }
    };

    const clearState = () => {
        setTitle("");
        setContent("");
        setIsUserSpecific(false);
        setUsers([]);
        setSelectedUsersList([]);
        setTotalHits(0);
        setPageRequest({...initialDescriptionShort});
    };

    const addUserList = (checked: boolean, user: User) => {
        if (checked) {
            let temp = selectedUsersList
            temp.push(user)
            setSelectedUsersList(temp)
        } else {
            let newList = selectedUsersList.filter((item: User) => item.username !== user.username);
            setSelectedUsersList(newList)
        }
    };

    const addUserCSV = () => {
        addUsersInternal(csvFile)
        setCsvFile([])
        onCloseList()
    };

    const addUsers = () => {
        addUsersInternal(selectedUsersList)
        setSelectedUsersList([])
        setCurrentFilter("")
        onClose();
    };

    const addUsersInternal = (usersInternal: User[]) => {
        let newList
        if (users.length > 0) {
            newList = usersInternal.filter((item: User) => !users?.some(user=> item.username === user.username));
        } else {
            newList = usersInternal
        }
        let temp = users
        newList.forEach((item: User) => {
            temp.push(item);
        })
        setUsers(temp);
    };

    const [currentFilter, setCurrentFilter] = useState("");
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const isFirstOpenRef = React.useRef(true);
    const previousFilterRef = React.useRef<string | undefined>(undefined);
    const previousPageRequestRef = React.useRef<PageRequest | undefined>(undefined);

    const searchUser = React.useCallback((pattern: string) => {
        if ((pattern !== "") && (currentFilter !== pattern)) {
            setPageRequest(prevState => ({ ...prevState, page: 0 }));
        } else {
            setCurrentFilter(pattern);
        }
        dispatch(userSearchByPattern(pattern, pageRequest, setTotalHits, setUsersList, toast));
    }, [dispatch, pageRequest, setTotalHits, setUsersList, toast]);

    const debouncedSearchUser = React.useCallback(
        debounce((pattern: string) => {
            searchUser(pattern);
        }, 500),
        [searchUser]
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearchUser(e.target.value);
    };

    useEffect(() => {
        if (isOpen) {
            if (isFirstOpenRef.current || currentFilter !== previousFilterRef.current || pageRequest.page !== previousPageRequestRef.current?.page) {
                debouncedSearchUser(currentFilter);
            }
            isFirstOpenRef.current = false;
        }
        previousFilterRef.current = currentFilter;
        previousPageRequestRef.current = pageRequest;

        return () => {
            debouncedSearchUser.cancel();
        };
    }, [currentFilter, debouncedSearchUser, isOpen]);

    const sendMessageAll = (custom : CustomMessage) => {
        dispatch(setLoading(true));
        sendToAll(custom).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                toast({
                    title: "Notification",
                    position: "top",
                    description: "Message sent successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                clearState()
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to send custom Message",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    const sendMessageList = (custom : CustomMessage) => {
        dispatch(setLoading(true));
        sendToGroup(custom).then((response: AxiosResponse) => {
            if ([200, 201].includes(response.status)) {
                toast({
                    title: "Notification",
                    position: "top",
                    description: "Message sent successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                })
                clearState()
            } else {
                return Promise.reject(response.data.apierror.message);
            }
        }).catch( (error: any) => {
            toast({
                title: "Failed to send custom Message",
                position: "bottom",
                description: JSON.stringify(error?.response?.data?.apierror ? error.response.data.apierror.debugMessage : error?.message),
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        })
    };

    const handleOnDrop = (data: Array<ParseResult<any>>) => {
        let body : User[] = []
        data.forEach((row: ParseResult<any>) => {
            //handle parsing errors
            if (!row.errors || !(row.errors.length > 0)) {
                //skip bad rows
                if (!row.meta.aborted && !row.meta.truncated) {
                    let rowData : string[] = row.data
                    //skip headers
                    if (rowData[0]!=='Username') {
                        let user: User = {
                            username: rowData[0],
                            email: rowData[1]
                        }
                        body.push(user);
                    }
                }
            }
        })
        setIsImportDisabled(false)
        setCsvFile(body)
    };

    const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
        setIsImportDisabled(true)
        setCsvFile([])
    };

    const handleOnRemoveFile = (data: any) => {
        setIsImportDisabled(true)
        setCsvFile([])
    };

    const columnsData = [
        {
            Header: "USERNAME",
            accessor: "username"
        },
        {
            Header: "FIRST NAME",
            accessor: "profile.firstName"
        },
        {
            Header: "LAST NAME",
            accessor: "profile.lastName"
        }
    ];

    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem color="gostation.500" isCurrentPage>
                      <BreadcrumbLink as={Link} to="/notification">
                          <Text>Notifications</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          <Flex w="96%" h="90%" minH="500px" bg="white" ml={6} mr={6}>
              <VStack>
                  <HStack alignItems="flex-start">
                      <VStack minH="300px" pl={4} pr={4} alignItems="left">
                          <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Message details</Heading>
                          <FormControl
                                       isInvalid={!!errors?.title?.message}
                                       errortext={errors?.title?.message}>
                              <FormLabel fontSize="xs" color="gray">Message Title</FormLabel>
                              <Input {...register("title")} isRequired placeholder="Title" value={title} size="md" onChange={(e) => setTitle(e.target.value)}  />
                              <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                          </FormControl>
                          <HStack spacing={60}>
                              <Text fontSize="xs" color="gray">Message content</Text>
                              <Text pl={12} fontStyle="italic" fontSize="xs" color="gray">max. 100 characters</Text>
                          </HStack>
                          <FormControl
                              isInvalid={!!errors?.content?.message}
                              errortext={errors?.content?.message}>
                              <Textarea {...register("content")} mb={4} value={content} placeholder="Type content here..." size="md" onChange={(e) => setContent(e.target.value)}/>
                              <FormErrorMessage>{errors?.content?.message}</FormErrorMessage>
                          </FormControl>
                          <FormControl mt={4}>
                              <Checkbox
                                  isChecked={silent}
                                  onChange={(e) => setSilent(e.target.checked)}
                                  colorScheme="gostation"
                              >
                                  Silent Notification
                              </Checkbox>
                          </FormControl>
                      </VStack>
                      <Center h="300px">
                          <Divider orientation="vertical" />
                      </Center>
                      <VStack minH="300px" minW="513px" pl={4} pr={4} alignItems="left">
                          <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500" textAlign="left">Target Users</Heading>
                          <HStack spacing={10}>
                              <Text fontSize="xs" color="gray">Criteria</Text>
                              {isUserSpecific && <Text pl={20} fontSize="xs" color="gray">Specify Users</Text>}
                          </HStack>
                          <HStack spacing={5}>
                              <Checkbox isChecked={isUserSpecific} onChange={(e) => setIsUserSpecific(e.target.checked)}>Is User Specific</Checkbox>
                              {isUserSpecific && <Button onClick={onOpen} colorScheme="gostation" bg="gostation.100" variant="solid" size="md">SELECT USER/S</Button>}
                              <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="none" size="lg">
                                  <ModalOverlay />
                                  <ModalContent minH="450px" width="fit-content" minW="950px">
                                      <ModalHeader color="gostation.500">Select Users allowed to send a Notification</ModalHeader>
                                      <ModalCloseButton />
                                      <ModalBody>
                                          <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
                                              <InputGroup size="sm" w="60%" >
                                                  <Input
                                                      type="search"
                                                      name="search"
                                                      placeholder="Search users by username, phone number or email"
                                                      onChange={handleInputChange}
                                                  />
                                                  <InputRightElement width="2.5rem">
                                                      <Icon as={BiFilter}/>
                                                  </InputRightElement>
                                              </InputGroup>
                                          </Flex>
                                          <Box  borderBottomWidth="1px">
                                              <Stack direction="row" spacing={1} pt={3}>
                                                  <Menu>
                                                      <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
                                                          Users/page: {pageRequest.pageSize}
                                                      </MenuButton>
                                                      <MenuList>
                                                          <MenuItem value={5} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>5</MenuItem>
                                                          <MenuItem value={10} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>10</MenuItem>
                                                          <MenuItem value={15} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>15</MenuItem>
                                                      </MenuList>
                                                  </Menu>
                                                  <Spacer/>
                                                  <IconButton
                                                      variant="outline"
                                                      aria-label="Previous page"
                                                      isDisabled={!isPrevPage(pageRequest, totalHits)}
                                                      boxSize={6}
                                                      icon={<ChevronLeftIcon />}
                                                      onClick={() => {prevPage(pageRequest, setPageRequest)}}
                                                  />
                                                  <Text pl={1} fontSize="sm">
                                                      {(pageRequest.pageSize * pageRequest.page)}-{Math.min(pageRequest.pageSize * (pageRequest.page +1), totalHits)} of {totalHits}
                                                  </Text>
                                                  <IconButton
                                                      variant="outline"
                                                      isDisabled={!isNextPage(pageRequest, totalHits)}
                                                      aria-label="Next page"
                                                      boxSize={6}
                                                      icon={<ChevronRightIcon />}
                                                      onClick={() => {nextPage(pageRequest, setPageRequest)}}
                                                  />
                                              </Stack>
                                          </Box>
                                          {isLoading ? (
                                                <div className="custom-loader">
                                                    </div>
                                            ) : (
                                          <Table variant="simple" size="sm">
                                              <Thead>
                                                  <Tr>
                                                      <Th/>
                                                      <Th color="gostation.500">USERNAME</Th>
                                                      <Th color="gostation.500">FIRST NAME</Th>
                                                      <Th color="gostation.500">LAST NAME</Th>
                                                      <Th color="gostation.500">PHONE NUMBER</Th>
                                                      <Th color="gostation.500">EMAIL</Th>
                                                  </Tr>
                                              </Thead>
                                              <Tbody>
                                                  {usersList && usersList.map((user: User , i: number) => {
                                                      return (
                                                          <Tr key={i}>
                                                              <Td><Checkbox onChange={(e) => addUserList(e.target.checked, user)}/></Td>
                                                              <Td>{user.username}</Td>
                                                              <Td>{user.profile?.firstName}</Td>
                                                              <Td>{user.profile?.lastName}</Td>
                                                              <Td>{user.profile?.phone}</Td>
                                                              <Td>{user.email}</Td>
                                                          </Tr>)
                                                  })}
                                              </Tbody>
                                          </Table>
                                          )}
                                      </ModalBody>
                                      <ModalFooter>
                                          <Button colorScheme="gostation" onClick={addUsers}>
                                              Add
                                          </Button>
                                      </ModalFooter>
                                  </ModalContent>
                              </Modal>
                              {isUserSpecific && <Button ml="auto" leftIcon={<MdFileUpload/>} colorScheme="gostation" bg="gostation.100" variant="solid" size="md" onClick={onOpenList}>UPLOAD USER LIST</Button>}
                              <Modal isCentered isOpen={isOpenList} onClose={onCloseList} motionPreset="none">
                                  <ModalOverlay />
                                  <ModalContent>
                                      <ModalHeader color="gostation.500">Upload User List CSV</ModalHeader>
                                      <ModalCloseButton />
                                      <ModalBody>
                                          <Stack direction="column">
                                              <Text fontSize="xs">Upload a bulk list of Users</Text>
                                              <CSVReader
                                                  onDrop={handleOnDrop}
                                                  onError={handleOnError}
                                                  addRemoveButton
                                                  onRemoveFile={handleOnRemoveFile}
                                              >
                                                  <Text>Drop CSV file here or click to upload.</Text>
                                              </CSVReader>
                                              <Stack direction="row">
                                                  <Spacer/>
                                                  <Button isDisabled={isImportDisabled} maxW="40%" leftIcon={<MdFileUpload/>} colorScheme="gostation" variant="solid" size="sm" onClick={()=>{addUserCSV()}}>UPLOAD USER LIST</Button>
                                              </Stack>
                                          </Stack>
                                      </ModalBody>
                                  </ModalContent>
                              </Modal>
                          </HStack>
                          {isUserSpecific && <Box maxWidth="700px" mx="auto" mt="30px">
                              <CustomTable
                                  tableData={users}
                                  columnsData={columnsData}
                                  pageSizeDefault={10}
                              />
                          </Box>}
                      </VStack>
                  </HStack>
                  <Divider/>
                  <HStack spacing={6}>
                      <Button onClick={() => {clearState()}} ml="auto" colorScheme="gostation" variant="outline" size="md">CANCEL</Button>
                      <Button onClick={() => {confirmAction()}} mr="auto" colorScheme="gostation" variant="solid" size="md">SEND</Button>
                  </HStack>
                  <AlertDialog
                      isOpen={isOpenConfirm}
                      //@ts-ignore
                      leastDestructiveRef={cancelRef}
                      onClose={onCloseConfirm}
                  >
                      <AlertDialogOverlay>
                          <AlertDialogContent>
                              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                  Send Notification
                              </AlertDialogHeader>

                              <AlertDialogBody>
                                {isUserSpecific ? (
                                    <span>
                                        Please confirm to send this notification to <b>{users.length} users</b>?
                                    </span>
                                ) : (
                                    <span>
                                        Please confirm to send this notification to <b>ALL users</b>?
                                    </span>
                                )}
                              </AlertDialogBody>

                              <AlertDialogFooter>
                                  {/* @ts-ignore */}
                                  <Button ref={cancelRef} onClick={onCloseConfirm}>
                                      Cancel
                                  </Button>
                                  <Button colorScheme='green' onClick={saveAction} ml={3} disabled={isSendDisabled}>
                                    {isSendDisabled ? `Send (${sendCountdown})` : 'Send'}
                                  </Button>
                              </AlertDialogFooter>
                          </AlertDialogContent>
                      </AlertDialogOverlay>
                  </AlertDialog>
              </VStack>
          </Flex>
      </Box>
  );
}
