import * as React from "react"
import {
    useToast,
    Flex,
    Box,
    Input,
    Button,
    Text,
    Breadcrumb,
    BreadcrumbLink,
    BreadcrumbItem,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    InputRightElement,
    InputGroup,
    Stack,
    Spacer,
    Icon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Center,
} from "@chakra-ui/react"
import { Link, useHistory } from "react-router-dom"
import { useEffect, useState } from "react";
import userSearchByPattern from "../../helpers/userSearchHelper";
import { User } from "../../types/UserTypes";
import { changeSize, initialDescription, isNextPage, isPrevPage, prevPage, nextPage } from "../../utils/pagination";
import { BiFilter } from "react-icons/bi";
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, AddIcon } from "@chakra-ui/icons";
import { applyUsaPhoneMasking } from "../../utils/masking";
import Loader from "../../utils/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/index";
import { AgeGroup, AgeGroupDescriptions } from "../../enums/UserAgeGroupEnum";
import { Status, StatusDescriptions } from "../../enums/UserStatusEnum";
import { Gender, GenderDescriptions } from "../../enums/UserGenderEnum";
import { debounce } from 'lodash';
import { FiltersType } from "../../types/FiltersTypes";

export function Users() {
    const toast = useToast();
    const history = useHistory();

    let initialUsers : User[] = []

    const [usersList, setUsersList] = React.useState(initialUsers)
    const [pageRequest, setPageRequest] = React.useState(initialDescription)
    const [totalHits, setTotalHits] = React.useState(0)
    const [currentFilter, setCurrentFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState<Status>(Status.ALL);
    const [ageGroupFilter, setAgeGroupFilter] = useState<AgeGroup>(AgeGroup.ALL);
    const [genderFilter, setGenderFilter] = useState<Gender>(Gender.ALL);
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.loading.isLoading);
    const previousFilterRef = React.useRef(currentFilter);
    const previousPageRequestRef = React.useRef(pageRequest);
    const previousStatusFilterRef = React.useRef(statusFilter);
    const previousAgeGroupFilterRef = React.useRef(ageGroupFilter);
    const previousGenderFilterRef = React.useRef(genderFilter);

    const getStatus = (user: User) => {
      if (!user.enabled || user.accountExpired || user.accountLocked) {
          return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#EB5757" color="#EB5757">SUSPENDED</Center>;
      } else if (!user.validated ) {
          return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="#76777A" color="#76777A">INACTIVE</Center>;
      } else {
          return <Center p={2} fontSize="sm" fontWeight="bold" borderRadius="10px" maxW="120px" borderWidth={2} borderColor="primary.500" color="primary.500">ACTIVE</Center>;
      }
    };

    const searchUser = React.useCallback((pattern: string) => {
        setCurrentFilter(pattern);

        if (pattern !== "" && currentFilter !== pattern) {
            setPageRequest(prevState => ({ ...prevState, page: 0 }));
        }

        const hasFilters = statusFilter !== Status.ALL || ageGroupFilter !== AgeGroup.ALL || genderFilter !== Gender.ALL;
    
        const additionalFilters = {
            status: statusFilter,
            ageGroup: ageGroupFilter,
            gender: genderFilter
        };
    
        const updatedPageRequest = {
            ...pageRequest,
            pageSize: hasFilters ? 99999 : initialDescription.pageSize 
        };
        
        dispatch(userSearchByPattern(pattern, updatedPageRequest, setTotalHits, setUsersList, toast, additionalFilters));
    }, [dispatch, pageRequest, setTotalHits, setUsersList, toast, currentFilter, statusFilter, ageGroupFilter, genderFilter]);    
  

    const debouncedSearchUser = React.useCallback(
        debounce((pattern: string) => {
            searchUser(pattern);
        }, 500),
        [searchUser]
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearchUser(e.target.value);
    };

    function haveFiltersChanged(current: FiltersType, previous: FiltersType) {
      return current.currentFilter !== previous.currentFilter ||
             current.pageRequest?.page !== previous.pageRequest?.page  ||
             current.pageRequest?.pageSize !== previous.pageRequest?.pageSize ||
             current.statusFilter !== previous.statusFilter ||
             current.ageGroupFilter !== previous.ageGroupFilter ||
             current.genderFilter !== previous.genderFilter;
    }
  
    useEffect(() => {
      const currentFilters = {
          currentFilter,
          pageRequest,
          statusFilter,
          ageGroupFilter,
          genderFilter
      };
      const previousFilters = {
          currentFilter: previousFilterRef.current,
          pageRequest: previousPageRequestRef.current,
          statusFilter: previousStatusFilterRef.current,
          ageGroupFilter: previousAgeGroupFilterRef.current,
          genderFilter: previousGenderFilterRef.current
      };
  
      if (!previousFilterRef.current ||   haveFiltersChanged(currentFilters, previousFilters)) {
          debouncedSearchUser(currentFilter);
      }
  
      previousFilterRef.current = currentFilter;
      previousPageRequestRef.current = pageRequest;
      previousStatusFilterRef.current = statusFilter;
      previousAgeGroupFilterRef.current = ageGroupFilter;
      previousGenderFilterRef.current = genderFilter;
  
      return () => {
          debouncedSearchUser.cancel();
      };
    }, [currentFilter, pageRequest, statusFilter, ageGroupFilter, genderFilter, debouncedSearchUser]);
  
    return (
      <Box w="100%" h="90%" bg="background.secondary">
          <Flex w="100%" h="10%" pl={6} alignItems="center">
              <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
                  <BreadcrumbItem isCurrentPage>
                      <BreadcrumbLink as={Link} to="/users">
                          <Text>Users</Text>
                      </BreadcrumbLink>
                  </BreadcrumbItem>
              </Breadcrumb>
          </Flex>
          <Flex h="8%" ml={6} pl={4} pt={2} pb={2} mr={6} pr={4} alignItems="center" bg="white">
              <InputGroup size="sm" w="50%" >
                  <Input
                      type="search"
                      name="search"
                      placeholder="Search users by username, phone number, zipcode or email"
                      onChange={handleInputChange}
                  />
                  <InputRightElement width="2.5rem">
                      <Icon as={BiFilter}/>
                  </InputRightElement>
              </InputGroup>
              <Button as={Link} to="/users/add" leftIcon={<AddIcon />} ml={2} colorScheme="primary" variant="solid" size="sm">NEW USER</Button>
          </Flex>
          <Box h="6%" ml={6} pl={4} mr={6} pr={4} borderBottomWidth="1px">
              <Stack direction="row" spacing={1} pt={3}>
                  <Menu>
                      <MenuButton as={Button} size="xs" rightIcon={<ChevronDownIcon />}>
                          Users/page: {pageRequest.pageSize}
                      </MenuButton>
                      <MenuList>
                          <MenuItem value={10} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>10</MenuItem>
                          <MenuItem value={50} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>50</MenuItem>
                          <MenuItem value={100} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>100</MenuItem>
                          <MenuItem value={9999} onClick={(e) => {changeSize(e.target as HTMLButtonElement, pageRequest, setPageRequest)}}>9999</MenuItem>
                      </MenuList>
                  </Menu>
                  <Menu>
                    <MenuButton as={Button} size="xs">
                        Status: {StatusDescriptions[statusFilter]}
                    </MenuButton>
                    <MenuList>
                        {Object.entries(StatusDescriptions).map(([value, text]) => (
                            <MenuItem key={value} onClick={() => setStatusFilter(value as Status)}>
                                {text}
                            </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                  <Menu>
                    <MenuButton as={Button} size="xs">
                        Age Group: {AgeGroupDescriptions[ageGroupFilter as AgeGroup]}
                    </MenuButton>
                    <MenuList>
                        {Object.entries(AgeGroupDescriptions).map(([value, text]) => (
                            <MenuItem key={value} onClick={() => setAgeGroupFilter(value as AgeGroup)}>
                                {text}
                            </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                  <Menu>
                    <MenuButton as={Button} size="xs">
                        Gender: {GenderDescriptions[genderFilter]}
                    </MenuButton>
                    <MenuList>
                        {Object.entries(GenderDescriptions).map(([value, text]) => (
                            <MenuItem key={value} onClick={() => setGenderFilter(value as Gender)}>
                                {text}
                            </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                  <Spacer/>
                  <IconButton
                      variant="outline"
                      aria-label="Previous page"
                      isDisabled={!isPrevPage(pageRequest, totalHits)}
                      boxSize={6}
                      icon={<ChevronLeftIcon />}
                      onClick={() => {prevPage(pageRequest, setPageRequest)}}
                  />
                  <Text pl={1} fontSize="sm">
                      {(pageRequest.pageSize * pageRequest.page)}-{Math.min(pageRequest.pageSize * (pageRequest.page +1), totalHits)} of {totalHits}
                  </Text>
                  <IconButton
                      variant="outline"
                      isDisabled={!isNextPage(pageRequest, totalHits)}
                      aria-label="Next page"
                      boxSize={6}
                      icon={<ChevronRightIcon />}
                      onClick={() => {nextPage(pageRequest, setPageRequest)}}
                  />
              </Stack>
          </Box>
          {isLoading ? (
            < Loader />
            ) : (
          <Table variant="simple" size="sm">
              <Thead>
                  <Tr>
                      <Th color="gostation.500">USERNAME</Th>
                      <Th color="gostation.500">FIRST NAME</Th>
                      <Th color="gostation.500">LAST NAME</Th>
                      <Th color="gostation.500">E-MAIL</Th>
                      <Th color="gostation.500">PHONE NUMBER</Th>
                      <Th color="gostation.500">ZIP CODE</Th>
                      <Th color="gostation.500">STATUS</Th>
                      <Th color="gostation.500">LAST ACTIVITY</Th>
                  </Tr>
              </Thead>
              <Tbody>
                  {usersList && usersList.map((user: User , i: number) => {
                      return (
                      <Tr onClick={() => {history.push("/users/" + user.username)}} key={i} _hover={{cursor: "pointer"}}>
                          <Td>{user.username}</Td>
                          <Td>{user.profile?.firstName}</Td>
                          <Td>{user.profile?.lastName}</Td>
                          <Td>{user.email}</Td>
                          <Td>{applyUsaPhoneMasking(user.profile?.phone !== undefined ? user.profile?.phone : "")}</Td>
                          <Td>{user.profile?.zipCode}</Td>
                          <Td>{getStatus(user)}</Td>
                          <Td>{user.lastLoginTime?.substr(0, user.lastLoginTime.indexOf('T'))}</Td>
                      </Tr>)
                  })}
              </Tbody>
          </Table>
        )}
      </Box>
  );
}


